<script lang="ts">
export enum Direction {
  Up = 'up',
  Down = 'down',
  Left = 'left',
  Right = 'right',
}
</script>

<script setup lang="ts">
import { PropType } from 'vue';

const degrees: { [key in Direction]?: string } = {
  up: 'rotate-[270deg]',
  down: 'rotate-90',
  left: 'rotate-180',
  right: 'rotate-0',
};

const props = defineProps({
  direction: {
    type: String as PropType<Direction>,
    required: true,
  },
});
</script>

<template>
  <svg
    class="w-3 h-3 fill-current"
    xmlns="http://www.w3.org/2000/svg"
    xml:space="preserve"
    width="298"
    height="512"
    shape-rendering="geometricPrecision"
    text-rendering="geometricPrecision"
    image-rendering="optimizeQuality"
    fill-rule="evenodd"
    clip-rule="evenodd"
    viewBox="0 0 298 511.93"
    :class="degrees[props.direction]"
  >
    <path
      fill-rule="nonzero"
      :d="`
        M70.77 499.85c-16.24 16.17-42.53 16.09-58.69-.15-16.17-16.25-16.09-42.54.15-58.7l185.5-185.03L12.23 
        70.93c-16.24-16.16-16.32-42.45-.15-58.7 16.16-16.24 42.45-16.32 58.69-.15l215.15 
        214.61c16.17 16.25 16.09 42.54-.15 58.7l-215 214.46z
      `"
    />
  </svg>
</template>

<style scoped>
  svg {
    transition: all 0.1s;
  }
</style>
